<script lang="ts">
  import Logger from '@yakit/core/logger'; const log = Logger(false)
  import { onMount } from 'svelte'
  import { f7, app, App, Panel, View} from 'framework7-svelte';
  import { QueryClient, QueryClientProvider } from '@sveltestack/svelte-query/svelte'
  import Sidebar from './pages/sidebar/Sidebar.svelte';
  import injector from '~/injector'
  import routesHolder from './pages/routes/holder';
  import LoginPopup from './pages/login-popup.svelte';
  import type { Writable } from 'svelte/store';
  import growl from '@yakit/ui/growl'

  let isAuthenticated = injector.get('isAuthenticated') as Writable<boolean>
  let isViewReady = routesHolder.isViewReady
  let theme = 'aurora';
  let routesReady = routesHolder.isReady
  let mainView

  if (document.location.search.indexOf('theme=') >= 0) {
    theme = document.location.search.split('theme=')[1].split('&')[0];
  }

  const f7Params = {
    id: 'io.greenbill.rcm',
    theme,
    //use core routes by default
    routes: routesHolder.getCommonRoutes(),
    // touch: {
    //   auroraTouchRipple: true,
    // },
    popup: {
      closeOnEscape: true,
    },
    sheet: {
      closeOnEscape: true,
    },
    popover: {
      closeOnEscape: true,
    },
    actions: {
      closeOnEscape: true,
    }
  };

  // if its authenticated RcmAppState will setup the proper routes and set this to true
  $: if($routesReady){
    f7.routes = routesHolder.getRoutes()
    log("routes", f7.routes)
    // log("isAuthenticated f7 obj", f7)
  }

  function getURL() {
      return window.location.href
  }

  // let mainView
  // when the view is initialized then set the isViewinit store that the sidebar also references
  // and wil build menu once view is initialized.
  function mainViewInit(view) {
    log("*********** main view init", view)
    mainView = view
    // log("view routes", view.routes)
    //set isViewReady which gets picked up in sidebar
    routesHolder.isViewReady.set(true)
  }

  $: if($isViewReady){
    if(window.location.href.includes("verify-auth?token=")){

      setTimeout(() => {
        //delay so routes are all setup first.
        log("routing to home")
        mainView.router.navigate({name: 'home'})
        // mainView.router.refreshPage()
      }, 10);

    }
    // listener for copy to trim data before put it into clipboard
    document?.addEventListener('copy', e => {
      const selection = document.getSelection();
      e.clipboardData.setData("text/plain", selection.toString().trim());
      e.preventDefault();
    });


  }

  function verifyAuthViewInit(view) {
    log("fooViewInit init", view)
    // log("view routes", view.routes)
    // routesHolder.isViewReady.set(true)
  }
  // console.log("mainViewInit", mainViewInit.toString())

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        // staleTime: 20 * 1000 //60 seconds
      },
    },
  })

  /**
   * if redirected from oauth then this will get token from url and login
   * bit hacky but works for now
   */
  function verifyAuth(){
    if(window.location.href.includes("verify-auth?token=")){
      log("verify-auth window.location.href", window.location.href )
      log("verify-auth window.location.hash", window.location.hash )

      let href = window.location.href
      href = href.replace('#', '/')
      let sparams = new URLSearchParams( new URL(href).search )
      // log("sparams", sparams);
      const _token = sparams.get('token');
      // log("_token", _token )
      localStorage.setItem('bearer', _token)
      const appState = injector.get('appState')
      appState.isAuthenticated.set(true)
      appState.showLoginScreen.set(false)
      // appState.showLoginScreen.set(false)
      growl.success('Login Successful', 'Welcome')
    }
  }

  onMount(() => {
    verifyAuth()
  })

</script>

<App {...f7Params}>
  <!-- <Panel left cover resizable>
    <View url="/panel-left/" linksView=".view-main" />
  </Panel>
  <Panel right reveal resizable>
    <View url="/panel-right/" />
  </Panel> -->
  <!-- <View url="/verify-auth/" onViewInit={verifyAuthViewInit} /> -->
  <!-- <View url="/" main={true} class="safe-areas" browserHistory={true} browserHistorySeparator='#' browserHistoryInitialMatch={true}/> -->
  <QueryClientProvider client={queryClient}>
    <main class="views">
      <Sidebar />
      {#if $routesReady}
        <!-- preloadPreviousPage={false} will need to look into this. -->
        <article>
          <View url="/" main class="safe-areas"
            browserHistory={true} browserHistorySeparator='#' browserHistoryStoreHistory={false}
            browserHistoryInitialMatch={true} onViewInit={mainViewInit}/>
        </article>
      {/if}
    </main>
  </QueryClientProvider>
  <LoginPopup/>
</App>

<style>

  main {
    height: 100%;
    display: flex;
    flex-direction: row;
    flex: 1;
    overflow: hidden;
  }
  article {
    height: 100%;
    flex: 1;
  }

</style>
