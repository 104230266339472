import Logger from '@yakit/core/logger'; const log = Logger(false)
import { get } from 'svelte/store';
import { isFunction } from '@yakit/core/is'
import growl from "../../../growl"
import toast from "../../../growl/toast"
import mergeListConfig from "../mergeListConfig"
import {JqGridCtrl} from '../../../jqGrid'
import problemHandler from '../../../growl/problemHandler'
import { f7 } from 'framework7-svelte';
import { createEventDispatcher } from 'svelte';

const not_implemented = "not implemented"

const JqGridListManager = ({ resource }) => {

  const gridCtrl = new JqGridCtrl()
  gridCtrl.resource = resource
  const listId = resource.ident()
  let dlg = f7.dialog

  let mgr = {
    resource,
    listId,
    gridCtrl,
    toolbarOptions: {},
    configOverride: {},

    setConfigOverride(configOverride) {
      mgr.configOverride = configOverride
    },

    async doConfig(ctx = {}) {
      let cfgFromServer = await resource.getConfigs()
      mgr.ctx = mergeListConfig(ctx, cfgFromServer, listId, mgr.configOverride)
      mgr.toolbarOptions = ctx.toolbarOptions
      mgr.searchFormOpts = ctx.searchFormOpts
      // console.debug("ctx", ctx)
      return ctx
    },

    // Fires for common actions, will fall back to ctx.toobarHandler if nothing exists
    handleAction(event, btnItem) {
      log("listDataCtrl handleAction", btnItem, event)
      switch (btnItem.key) {
        case 'xlsExport':
          return mgr.gridCtrl.xlsExport()
        case 'delete':
          return mgr.deleteSelected(event)
        default:
          if(mgr.ctx.toolbarHandler){
            return mgr.ctx.toolbarHandler(btnItem, event)
          }
          //FIXME this is kind of dangerous and should be removes and use ctx.toolbarHandler
          else if (isFunction(mgr[btnItem.key])) {
            return mgr[btnItem.key](btnItem, event)
          }
      }
    },

    deleteSelected(event) {
      const noConfirm = event.metaKey || event.ctrlKey
      const selectedIdSet = get(resource.selectedIds)
      if (selectedIdSet.size > 1) {
        if (resource.deleteSelected) {
          resource.deleteSelected([...selectedIdSet])
        } else {
          growl.warn("Single item deletes are supported. Bulk deleting will be supported in a future release")
        }
      } else {
        const id = [...selectedIdSet][0]
        mgr.delete(id, noConfirm)
      }
    },

    async delete(id, noConfirm) {
      const delFn = async () => {
        try {
            let res = await resource.delete(id)
            toast(res)
        } catch (er) {
          problemHandler.handleError(er)
        }
      }
      if(noConfirm){
        delFn()
      } else {
        dlg.confirm('This action cannot be undone.<br><br><small>💡 Hold the Ctrl key while clicking to bypass this confirmation</small>',
          'Delete confirmation', delFn);
      }
    },

    reloadKeepSelected() {
      //clearSelection and the reactive sync in the component will add them back
      mgr.gridCtrl.clearSelection()
      resource.reload()
    },

    clearSelection() {
      //clearSelection and the reactive sync in the component will add them back
      mgr.gridCtrl.clearSelection()
    },

    getSelectedIds() {
      return [...get(resource.selectedIds)]
    },

    updateFooter(info) {
      mgr.gridCtrl.updateFooter(info)
    },

    resetSort() {
      mgr.gridCtrl.resetSort()
    },

    handleError(er) {
      console.error(er)

      const message = er?.response?.status === 500 ? 'Unexpected error' : null
      growl.error(message || er)
    },

    handleResults(response) {
      if (response.ok) {
        growl.success(`${response.success.join('<br>')}`, response.defaultMessage)
      } else {
        growl.error(`${response.failed.join('<br>')} `, response.defaultMessage)
      }
    },
  }

  return mgr //Object.assign(ctrl, opts)
}

export default JqGridListManager
