import { tempLinkDownloader } from "~/shared/downloader/blobDownloader"
import { f7 } from 'framework7-svelte';

let exportFormat = 'xlsx' //hard code to xlsx for now but will be slecteable in future
let max = 1000

const defaultParams = { format: exportFormat, max }

const xlsxExport = async (resource, filters) => {
  f7.progressbar.show('multi')
  const key = resource.dataApi.path
  const searchParams = { q: filters, ...defaultParams }
  const blob = await resource.dataApi.download(searchParams)
  const name = key.replace(/\//g, "_") + "." + exportFormat
  tempLinkDownloader(blob, name)
  f7.progressbar.hide()
}

export default xlsxExport
