import PayDetailQuery from './PayDetailQuery'
import PayDetailTranQuery from './PayDetailTranQuery'
import {PaymentApi} from '~/api/autocash'
import { get, writable } from 'svelte/store';
import { makeQueryStore } from '@yakit/core/resource/QueryStore'
import { extend } from '@yakit/core/objectz';


function PaymentQuery(batchQuery){

  const payApi = PaymentApi({ky: batchQuery.dataApi.ky})
  //the base store that gets extended here
  const paymentQuery = makeQueryStore({dataApi: payApi})
  const payDetailQuery = PayDetailQuery({ paymentQuery })
  let payDetailTranQuery

  //some store refs used below
  let {activeModel, currentPage, setSelected, setActiveFromPageData} = paymentQuery

  //the sum totals store
  const totals = writable({})
  const getTotals = () => { get(totals) }

  const updateTotals = async (_batch) => {
    const batch = _batch || batchQuery.activeModel.get()
    if(!batch) return
    const sumData = await payApi.sumTotals(batch)
    totals.set(sumData)
  }
  const initFormData = writable({})
  /**
  * initialize the store subscriptions
  * @Overrides Resource
  */
  const init = async () => {
    if(paymentQuery.isReady()) return
    await paymentQuery.loadConfigs()
    payDetailQuery.init()
    //payDetailTranQuery.init()

    let unsubs = [

      paymentQuery.selectedIds.subscribe(async (selectedIdSet) => {
        //make into array
        let selectedIds = [...selectedIdSet]
        if (selectedIds.length === 0) {
          paymentQuery.clearDetails()
          payDetailQuery.totals.set(0)
        }
        else if (selectedIds.length > 1) {
          paymentQuery.clearActiveModel()
          paymentQuery.clearDetails()
          payDetailQuery.totals.set(0)
        } else {
          //clear the page so it doesn't show last payment's info.
          payDetailQuery.clearCurrentPage()
          payDetailQuery.totals.set(0)
          payDetailTranQuery?.clearCurrentPage()
          const paymentId = selectedIds[0]

          setActiveFromPageData(paymentId)
          payDetailQuery.restrictSearch.set({paymentId})
          await payDetailQuery.reload()
          //FIXME for now keep it here but should only happen if its shown
          if (payDetailTranQuery) {
            payDetailTranQuery.restrictSearch.set({paymentId})
            await payDetailTranQuery.reload()
          }

        }
      }),
      // set current selected item if it only has one record.
      currentPage.subscribe(page => {
        if(page?.data?.length === 1) {
          let firstItem = page.data[0]
          setSelected([firstItem.id])
          activeModel.set(firstItem)
        }
      })
    ]
    paymentQuery.unsubs.concat(unsubs)

    // SET default max to 10 for payments
    paymentQuery.max.set(10)

    paymentQuery.setReady()
  }

  const setPayDetailTranQuery = (pdTranQuery) => {
    payDetailTranQuery = pdTranQuery
  }

  /**
   * Makes the rpc calls to server
   */
  const rpc = async (name, data) => {
    const res = await payApi.rpc(name, data)
    batchQuery.loadCurrentWithoutSubs()
    return res
  }

  const removeDetail = async () => {
    const paymentId = paymentQuery.activeModel.getId()
    const res = await payApi.rpc('removeDetail', {ids: [paymentId]})
    await payDetailQuery.reload()
    await payDetailTranQuery.reload()
    return res
  }

  const deleteSelected = async (ids) => {
    await payApi.rpc('delete', { ids })
    paymentQuery.reload()
  }

  const pqReload = paymentQuery.reload

  extend(paymentQuery, {
    /** init method will set this to true once done */
    payDetailQuery,
    payDetailTranQuery,
    setPayDetailTranQuery,
    totals,
    updateTotals,
    init,
    initFormData,
    rpc,
    removeDetail,
    deleteSelected,
    /** gets the total values */
    getTotals(){
      return get(totals)
    },
    async reload(){
      await paymentQuery.query({page: get(currentPage).page})
      await updateTotals()
    },
    clear(){
      paymentQuery.clearAll()
      payDetailQuery.clearAll()
      payDetailTranQuery && payDetailTranQuery.clearAll()
    },
    clearDetails(){
      payDetailQuery.clearAll()
      payDetailTranQuery && payDetailTranQuery.clearAll()
    }
  })

  return paymentQuery
}

export default PaymentQuery

