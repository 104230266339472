// import { get, writable } from 'svelte/store';
import Logger from '@yakit/core/logger'; let log = Logger(false);
import { cloneDeep, defaultsDeep, merge } from '@yakit/core/objectz'
import { isEmpty} from '@yakit/core/is'

let defaultToolbarOpts = {
  selectedButtons: {
    bulkUpdate: { icon: 'edit_note', tooltip: 'Bulk Update', enabled: false },
    xlsExport: { icon: 'view_module', label: 'Export to Excel', enabled: true }
  },
  leftButtons: {
    create: { icon: 'add_box', tooltip: 'Create New', enabled: false }
  },
  searchFormButton: { icon: 'mdi-text-box-search-outline', tooltip: 'Show Search Filters Form'}
}

/**
 * Merges server config into the config with defaults
 * returns the same config instance passed in as first arg.
 */
const mergeListConfig = (cfg, cfgFromServer, listId, configOverride={}) => {

  cfgFromServer = isEmpty(cfgFromServer) ? {} : cloneDeep(cfgFromServer)

  //make sure it has objects setup, so merge empty if not
  //NOTE: whole thing is hacky and needs to be cleaned up and synchronized on server
  defaultsDeep(cfgFromServer, {
    gridOptions: {
      toolbarOptions: {}
    }
  })

  cfgFromServer.toolbarOptions = cfgFromServer.gridOptions.toolbarOptions
  //remove toolbarOptions from under gridOptions, its there on server but at root level of cfg here on ui client
  delete cfgFromServer.gridOptions.toolbarOptions
  //overwrite whats defaults in cfg if server has it configured
  merge(cfg, cfgFromServer)
  //in some cases we need to override config from server
  merge(cfg, configOverride)
  
  //now toolbar defaults onto cfg.
  defaultsDeep(cfg, {
    toolbarOptions: cloneDeep(defaultToolbarOpts)
  })

  const tbOpts = cfg.toolbarOptions
  const gridOpts = cfg.gridOptions

  let createBtn = tbOpts.leftButtons.create

  //it it has editForm configured AND its not explicitly disabled
  if ((cfg.editForm || cfg.editPopover ) && gridOpts.createEnabled !== false) {
    createBtn.enabled = true
  }

  // setup create popover
  if(createBtn.enabled ){
      createBtn.popoverId = `#${listId}-popover-edit`
  }

  if (cfg.bulkUpdateForm) {
    tbOpts.selectedButtons.bulkUpdate.enabled = true
    tbOpts.selectedButtons.bulkUpdate.popoverId = `#${listId}-popover-bulk`
  }

  log("**** listConfig cfg", cfg)
  return cfg
}

export default mergeListConfig
