import growl from "@yakit/ui/growl"
import problemHandler from "@yakit/ui/growl/problemHandler"
import { getUser } from "~/user"

/**
 * Customer Dash info endpoints
 */
export function createTranShowController({ user, dataApiFactory, appState }){

  // $: card1 = transformFields($user.isCustomer ? custConfig.card1 : config.card1)
  // $: card2 = transformFields($user.isCustomer ? custConfig.card2 : config.card2)
  const dataApi = dataApiFactory.ar.tran
  const payTranHeader = getPayTranHeader()
  const memoTranHeader = getMemoTranHeader()
  const invTranHeader = getMemoTranHeader(user)

  const invToolbarOpts = getInvToolbarOpts()
  const payToolbarOpts = getPayToolbarOpts()
  const disputePopoverSchema = getDisputePopoverSchema()

  const reverse = async (tran) => {
    const results = await dataApi.rpc('reverse', {ids: [tran.id]})
          results.ok ? growl.success(results.title) : problemHandler.handleError(results)
  }


  return {
    payTranHeader,
    memoTranHeader,
    invTranHeader,
    invToolbarOpts,
    payToolbarOpts,
    disputePopoverSchema,
    reverse
  }
}

export function getMemoTranHeader(user) {
  const config = {
    card1: [
      { key: 'dueDate', format: 'date' },
      { label: 'Trans Date', key: 'tranDate', format: 'date' },
      { label: 'Trans Type', key: 'tranType.name' },
      { label: 'PO Num', key: 'ponum' },
      //{ label: 'Created By Payment', key:'autoCash.createdByPayNum'},
      { label: 'Trans Num', key: 'refnum' },  //till we add it to the header
      { label: 'SO Num', key: 'refnum2' },
      { label: 'Orig Trans Num', key: 'origRefnum' },
      { label: 'Created By ArBatch', key: 'createdArBatchId' },
      { label: 'Comments', key: 'comments' }
    ],
    card2: [
      { label: 'Balance', key: 'amount', format: 'money', class: 'font-bold' },    //RNDC custom (Balance Amount)
      { label: 'Orig Amount', key: 'origAmount', format: 'money', class: 'font-bold' },
      { label: 'Status', key: 'status.code' },
      { label: 'Term', key: 'term.name' },
      { label: 'Gross Amount', key: 'flex.num1', format: 'money'  },    //RNDC custom (Alpha only))
      { label: 'Tax Amount', key: 'lineSum.taxAmount', format: 'money' },    //RNDC custom (Alpha only))
      { label: 'Disc Amount', key: 'flex.num2', format: 'money'  }, //RNDC custom (Alpha only))
      //    { key:'state' },
      { label: 'Order Type', key: 'flex.text1' },            //RNDC custom
      { label: 'Bill To Loc', key: 'flex.text4' },          //RNDC custom
      { label: 'Misc. Ref Num', key: 'flex.text2' }            //RNDC custom
    ]
  }
  //hack for cust for now
  return getUser().isCustomer ? custConfig : config

}

export function getPayTranHeader() {
  const payTranHeader = {
    card1: [
      { label: 'Date', key: 'tranDate', format: 'date' },
      { label: 'Type', key: 'tranType.name' },
      { label: 'Num', key: 'refnum' },  //till we add it to the header
      // { label: 'SO Num', key:'refnum2'},
      // { label: 'Orig Trans Num', key:'origRefnum'},

      { label: 'Comments', key: 'comments', itemClass: 'hide-if-null' }
    ],
    card2: [
      { label: 'Amount', key: 'origAmount', format: 'money-abs$', class: 'font-bold' },
      { label: 'Unapplied', key: 'amount', format: 'money-abs$' },    //RNDC custom (Balance Amount)
      { label: 'Status', key: 'status.code' },
      { label: 'Source Batch', key: 'createdArBatchId', itemClass: 'hide-if-null' },
    ]
  }
  return payTranHeader
}

export function getDisputePopoverSchema() {
  let editSchema = {
    'dispute.reason': {
      input: 'select',
      options: {
        isValueObject: true,
        dataApi: {
          key: "ar.reason",
          q: {
            "$or": [ { "restrictUpdate": "$isNull" }, { "restrictUpdate": 2 } ]
          }
        }
      }
    },
    'dispute.amount' :{
      type: 'number',
      format: 'money'
    },
    'dispute.note' :{
      maxLength: 1000
    },
  }
  return editSchema
}

export function getInvToolbarOpts() {
  const tbOpts = {
    leftButtons: {
      showInvoice: {
        icon: "receipt",
        tooltip: "Show Invoice"
      },
      dispute: {
        icon: "balance",
        tooltip: "Dispute",
        popoverId: 'dispute-popover-edit'
      },
      pay: {
        icon: "payments",
        tooltip: "Pay",
        // class: "button-outline"
      },
    }
  }
  return tbOpts
}

export function getPayToolbarOpts() {
  const tbOpts = {
    leftButtons: {
      //before enable make sure users cannot call it twice (double click).
      // reverse: {
      //   icon: "history",
      //   tooltip: "Reverse"
      // }
    }
  }
  return tbOpts
}



export const custConfig = {
  card1: [
    { key: 'dueDate', format: 'date' },
    { label: 'Trans Date', key: 'tranDate', format: 'date' },
    { label: 'Trans Type', key: 'tranType.name' },
    { label: 'PO Num', key: 'ponum' },
    //{ label: 'Created By Payment', key:'autoCash.createdByPayNum'},
    // { label: 'Trans Num', key:'refnum'},  //till we add it to the header
  ],
  card2: [
    { label: 'Balance', key: 'amount', format: 'money$' },    //RNDC custom (Balance Amount)
    { label: 'Orig Amount', key: 'origAmount', format: 'money$' },
    //  { label: 'Gross Amount', key:'flex.num1', type: 'amount', input: 'amount'},    //RNDC custom (Alpha only))
    //  { label: 'Disc Amount', key:'discAmount', type: 'amount', input: 'amount'}, //RNDC custom (Alpha only))
    //    { key:'state' },
    { label: 'Status', key: 'status.code' },
    { label: 'Terms', key: 'term.name' },
  ]
}
